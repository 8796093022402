import React from 'react';
import styled, { css } from 'styled-components';

import { mobile } from '../../../../shared/styles/media-queries';
import SectionTitle from '../section-title';

import PhonesMobile from '../../../../images/parceiros/apple-one/phones-apple-one-mobile.png';

const Highlight = () => {
  return (
    <Wrapper>
      <AsideText>
        <SectionTitle>
          <RedText strong>Aproveite os serviços</RedText> Apple One
        </SectionTitle>
      </AsideText>
      <Section>
        <TextWrapper>
          <Text>
            <RedText strong>
              Você quem manda
            </RedText>
            <strong>Aproveite o Apple One, isto é Apple Music, Apple TV+, Apple Arcade e iCloud+.</strong>
          </Text>
        </TextWrapper>

        <TextWrapper>
          <Text>
            <RedText strong>
              Já possui o serviço e quer manter sua assinatura?
            </RedText>
            <strong> Você pode transferir a assinatura atual para o seu plano e economizar!</strong>
          </Text>
        </TextWrapper>

        <img className="image-phones" src={PhonesMobile} alt="Celulares apple" />

        <ContainerSecondaryInfo>
          <TextWrapper>
            <Text>
              <RedText display="inline" strong>
                +
              </RedText>
              <strong>Apple TV+: </strong> Veja conteúdos originais premiados e lançamento de filmes e séries, todos os meses;
            </Text>
          </TextWrapper>

          <TextWrapper>
            <Text>
              <RedText display="inline" strong>
                +
              </RedText>
              <strong>Apple Music: </strong> Ouça mais de 100 milhões de músicas sem comerciais;
            </Text>
          </TextWrapper>

          <TextWrapper>
            <Text>
              <RedText display="inline" strong>
                +
              </RedText>
              <strong>Apple Arcade: </strong> Jogue +200 games premium sem anúncios;
            </Text>
          </TextWrapper>

          <TextWrapper>
            <Text>
              <RedText display="inline" strong>
                +
              </RedText>
              <strong>iCloud+ </strong> Armazene arquivos e faça backups e muito mais;
            </Text>
          </TextWrapper>

          <TextWrapper>
            <Text>
              <RedText display="inline" strong>
                +
              </RedText>
              O compartilhamento pode ser feito para até 5 pessoas (titular e dependentes) para os serviços AppleTV+, Apple Arcade, iCloud+
            </Text>
          </TextWrapper>
        </ContainerSecondaryInfo>
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  color: #fff;
  overflow: hidden;
  padding: 80px 80px 0 80px;
  display: flex;
  flex-direction: row;

  ${mobile} {
    flex-direction: column;
    padding: 40px 16px;
  }
`;

const AsideText = styled.aside`
  width: 220px;
  ${mobile} {
    width: 100%;
  }
`;

const Section = styled.section`
  padding-left: 40px;
  position: relative;

  .image-phones {
    display: none;
    margin-left: -16px;
  }

  ${mobile} {
    padding-left: 0;
    .image-phones {
      display: block;
    };
  };


`;

export const ContainerSecondaryInfo = styled.div`
  margin-top: 40px;
  position: relative;
  max-width: 720px;
  width: 100%;

`;

const TextBase = css`
  font-weight: lighter;
  font-size: 24px;

  > strong {
    font-weight: bold;
  }

  ${mobile} {
    padding: 0 16px;
    margin: 32px 0 0 0;
  }
`;

const RedText = styled.span`
  color: #eb0028;
  display: ${({ display }) => display ?? 'block' };
  font-weight: ${({ strong }) => strong ? 700 : 400 };
`;

const TextWrapper = styled.div`
  ${TextBase}
`;

const Text = styled.p`
  margin: 0 0 21px 0;
`;

export default Highlight;
