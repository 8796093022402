import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Accessibility from 'accessibility-options';

import * as RegulationsService from '../../shared/services/regulations';
import { mobile } from '../../shared/styles/media-queries';
import { RegulationsWrapper, InfoPhrases } from '../../shared/components';
import AccessibilityBar from './components/accessibility';
import Carousel from './components/carousel';
import DependentLink from './components/dependent-link';
import Hero from './components/hero';
import Highlight from './components/highlight';
import HighlightAppleOne from './components/highlight/appleOne.js';
import InfoWrapper from './components/info-wrapper';
import SocialBox from './components/social-box';
import Roaming from './components/roaming';
import C6Info from './components/c6-info';
import PortabilityBonus from './components/portabilitybonus';
import InternetAccumulated from './components/internet-accumulated';
import LgpdBanner from '../../shared/components/lgpd-banner';
import Footer from '../../shared/components/footer';
import CoverageMap from '../../shared/components/coverage-map';
import { FooterFixed } from '../../shared/components';

import * as ParamsService from '../../shared/services/params';

const FamiliaPage = ({ pageContext: { content = {} } }) => {
  const pageName = 'familia';
  const [selectedPlan, changeSelectedPlan] = useState(content.cards && content.cards.find((card) => {
    return card?.order === '5';
  }) || content.cards[2]);
  const [pageVersion, changePageVersion] = useState('');

  useEffect(() => {
    const accessibility = new Accessibility();
    changePageVersion(ParamsService.get('v'));
    accessibility.init();
  }, []);

  const appleOneSkus = ['TPFF019', 'TPF0019', 'TPFF020', 'TPF0020'];

  const isAppleOne = appleOneSkus.includes(selectedPlan.sku);

  return (
    <Wrapper>
      <AccessibilityBar />

      <Hero plans={content.cards} selectedPlan={selectedPlan} changeSelectedPlan={changeSelectedPlan} pageVersion={pageVersion} />

      {selectedPlan && !isAppleOne ? (
        <Highlight
          mainSva={selectedPlan.mainSva}
          selectedPlan={selectedPlan}
        />
      ) : (
        <HighlightAppleOne
          mainSva={selectedPlan.mainSva}
          selectedPlan={selectedPlan}
        />
      )}

      <InternetAccumulated/>

      {!isAppleOne && (
        <PortabilityBonus/>
      )}

      {!isAppleOne && (
        <C6Info selectedPlan={selectedPlan}/>
      )}

      <Carousel selectedPlan={selectedPlan} />

      <Roaming selectedPlan={selectedPlan} isAppleOne={isAppleOne} />

      <SocialBox />

      <InfoWrapper />

      <DependentLink />

      <LgpdBanner />

      <InfoPhrases messages={content.footerMsg} />

      <RegulationsWrapper
        regulations={RegulationsService.get(content.regulations, content.summaries)}
        labelText="Faça download dos regulamentos e sumários"
        pageName={pageName}
      />

      <FooterFixed />
      <Footer template="-default" />
      <CoverageMap />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  ${mobile} {
    padding: 0 0 60px 0;
  }
`;

export default FamiliaPage;
