import React from 'react';
import styled, { css } from 'styled-components';

import { mobile } from '../../../../shared/styles/media-queries';
import SectionTitle from '../section-title';

const InternetAccumulated = () => {
  return (
    <Wrapper>
      <AsideText>
        <SectionTitle>
          Internet
          <RedText strong>acumulada!</RedText>
        </SectionTitle>
      </AsideText>

      <Section>
        <TextWrapper>
          <Text>
            A internet que você não usar em um mês
            <RedText strong>
            acumula para o mês seguinte.
            </RedText>
          </Text>
        </TextWrapper>
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  color: #fff;
  overflow: hidden;
  padding: 80px;
  display: flex;
  flex-direction: row;

  ${mobile} {
    flex-direction: column;
    padding: 16px;
  }
`;

const AsideText = styled.aside`
  width: 220px;
  ${mobile} {
    width: 100%;
  }
`;

const Section = styled.section`
  padding-left: 40px;

  ${mobile} {
    padding-left: 0;
  }
`;

export const ContainerSecondaryInfo = styled.div`
  margin-top: 40px;
  width: 385px;
  ${mobile} {
    width: 100%;
  }
`;

const TextBase = css`
  font-weight: lighter;
  font-size: 24px;

  > strong {
    font-weight: bold;
  }

  ${mobile} {
    padding: 0 16px;
    margin: 32px 0 0 0;
  }
`;

const RedText = styled.span`
  color: #eb0028;
  display: ${({ display }) => display ?? 'block' };
  font-weight: ${({ strong }) => strong ? 700 : 400 };
`;

const TextWrapper = styled.div`
  ${TextBase}

  span {
    width: 300px;
  }
`;

const Text = styled.p`
  margin: 0 0 21px 0;
`;

export default InternetAccumulated;
